/* eslint-disable */
import React from "react";
import ProductTemplate from "./product";
// import Title from "@components/common/Title";
// import Text from "@components/common/Text";
import NewProductTemplate from "../../components/sections/product-more-info/Item";

const Product = props => {
    const { pageContext } = props;
    const { item } = pageContext;

    const newProductsEnabled = process.env.GATSBY_NEW_PRODUCTS_ENABLED === "true";

    const Data = () => {
        return (
            <div className="space-y-5">
                <p>
                    <strong>
                        🔥 No deixis passar l'oportunitat de dominar la prova dels Mossos d'Esquadra
                        amb el nostre curs intensiu! 🔥
                    </strong>
                </p>

                <p>
                    Amb <strong>materials actualitzats</strong>, <strong>classes en directe</strong>{" "}
                    i <strong>suport constant</strong>, estaràs totalment preparat per afrontar
                    qualsevol repte. Inscriu-te ara i assegura't un{" "}
                    <strong>avantatge competitiu!</strong> 🚀
                </p>

                <p>
                    <strong>📅 Ja disponible el curs més complet</strong> per preparar la següent
                    convocatòria de Mossos d'Esquadra, programada per al segon trimestre de l'any{" "}
                    <strong>2025</strong>.
                </p>

                <p>
                    💪 <strong>El millor curs per preparar la primera prova amb garanties!</strong>
                </p>

                <p>
                    A iOpos tenim el nostre propi mètode d'estudi. Des del primer moment tindràs
                    accés a <strong>tot el contingut</strong>, que anirà creixent setmana rere
                    setmana amb noves activitats. Mai et traiem material, al contrari, sempre sumem
                    més!
                </p>

                <p>
                    🔄 <strong>Ara encara més renovat</strong>, amb més material i exercicis on els
                    nostres professors us avaluaran constantment.
                </p>

                <p>
                    🕒 <strong>Un curs que s'adapta a tu i als teus horaris!</strong>
                </p>

                <p>📺 Al curs trobaràs:</p>
                <ul>
                    <li>
                        📖 <strong>Vídeos explicatius</strong> de tot el temari específic
                    </li>
                    <li>
                        📰 <strong>Vídeos d'actualitat</strong> i psicotècnics aptitudinals
                    </li>
                    <li>
                        🎥 <strong>Classes en directe</strong> amb la professora, que quedaran
                        gravades per visualitzar-les tantes vegades com vulguis
                    </li>
                    <li>
                        🔄 <strong>Actualitzacions setmanals</strong>!
                    </li>
                </ul>

                <h3>
                    📚 <strong>CONTINGUT DEL CURS:</strong>
                </h3>
                <ul>
                    <li>
                        📑 <strong>Temari</strong>
                    </li>
                    <li>
                        📰 <strong>Totes les notícies d'actualitat</strong>
                    </li>
                    <li>
                        🧠 <strong>Psicotècnics</strong>
                    </li>
                    <li>
                        📺 <strong>Vídeos explicatius</strong>
                    </li>
                    <li>
                        📂 <strong>Material didàctic</strong>
                    </li>
                    <li>
                        ✍️ <strong>Resums</strong>
                    </li>
                    <li>
                        📝 <strong>Exercicis</strong>
                    </li>
                    <li>
                        ✅ <strong>Test</strong>
                    </li>
                    <li>
                        📌 <strong>Informació rellevant</strong>
                    </li>
                    <li>
                        💬 <strong>Tutories personalitzades</strong>
                    </li>
                </ul>

                <h3>
                    🔍 <strong>Estructura del curs:</strong>
                </h3>
                <h4>
                    📌 <strong>ÀMBIT A</strong>
                </h4>
                <ul>
                    <li>📜 Història de Catalunya (I & II)</li>
                    <li>👮‍♂️ Història de la Policia a Catalunya</li>
                    <li>🗣️ Àmbit sociolingüístic</li>
                    <li>🌍 Marc geogràfic de Catalunya</li>
                    <li>🏙️ Entorn social a Catalunya</li>
                    <li>💻 Les tecnologies de la informació</li>
                </ul>

                <h4>
                    ⚖️ <strong>ÀMBIT B</strong>
                </h4>
                <ul>
                    <li>📜 L'Estatut d'Autonomia de Catalunya</li>
                    <li>🏛️ Les institucions polítiques</li>
                    <li>⚖️ L'ordenament jurídic de l'Estat</li>
                    <li>🛡️ Els drets humans i constitucionals</li>
                    <li>⚖️ Poder Judicial i Tribunal Constitucional</li>
                    <li>🗺️ L'organització territorial de l'Estat</li>
                    <li>🇪🇺 La Unió Europea</li>
                </ul>

                <h4>
                    👮‍♂️ <strong>ÀMBIT C</strong>
                </h4>
                <ul>
                    <li>🚔 Competències de la Generalitat en seguretat</li>
                    <li>🏢 El Departament d'Interior</li>
                    <li>🤝 La coordinació policial</li>
                    <li>📜 El marc legal de la seguretat</li>
                    <li>⚖️ El codi deontològic policial</li>
                </ul>

                <h3>
                    📢 <strong>NOTÍCIES D'ACTUALITAT</strong>
                </h3>
                <ul>
                    <li>📰 Totes les notícies més rellevants</li>
                    <li>✅ Exercicis tests per posar-vos a prova</li>
                </ul>

                <h3>
                    🧠 <strong>PSICOTÈCNICS</strong>
                </h3>
                <ul>
                    <li>📺 Vídeos explicatius de tots els raonaments</li>
                    <li>🧩 Trucs i pràctica de psicotècnics</li>
                    <li>✅ Exercicis en forma de test</li>
                </ul>

                <h3>
                    📅 <strong>El curs estarà obert fins al dia de l'examen 2025!</strong>
                </h3>

                <h3>
                    💰 <strong>Preu: 599,99€</strong>
                </h3>
                <p>
                    ✅ Possibilitat de pagar en <strong>tres vegades</strong> a través de{" "}
                    <strong>PayPal sense interessos</strong>.
                </p>

                <p>
                    💬 <strong>Un cop matriculat, et posarem en un grup de treball</strong> amb
                    reptes setmanals i Kahoots interactius!
                </p>

                <p>
                    ⚠️ <strong>No deixis que t'ho expliquin!</strong> Apunta't ara i comença a
                    preparar-te per a la teva plaça als Mossos d'Esquadra! 🚓💨
                </p>

                <div className="overflow-hidden rounded-lg mt-10 border-4 border-gray-500 shadow-lg">
                    <iframe
                        title={item.title}
                        width="100%"
                        height="600px"
                        src="https://www.youtube.com/embed/fHlokDcqiek"
                        frameBorder={"0"}
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen={true}
                    />
                </div>
            </div>
        );
    };

    return (
        <>
            {!newProductsEnabled ? (
                <ProductTemplate item={item}>
                    <Data />
                </ProductTemplate>
            ) : (
                <NewProductTemplate
                    item={item}
                    title="🚨 Assegura la teva plaça als Mossos d'Esquadra amb el curs més complet i actualitzat! 🚨"
                >
                    <Data />
                </NewProductTemplate>
            )}
        </>
    );
};

export default Product;
